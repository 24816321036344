import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import { DateFilterReducer } from './reducers/DateFilterReducer';

const middleware = applyMiddleware(thunk);

const persistConfig = {
    key: 'root',
    storage,
  }

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    auth: AuthReducer,
    
		todoReducers,
    date_filter:DateFilterReducer,   
	form: reduxFormReducer,	
	
});

const persistedReducer = persistReducer(persistConfig, reducers)

// const store = createStore(rootReducers);

export const store = createStore(persistedReducer,  composeEnhancers(middleware));
export const persistor = persistStore(store)
