import swal from 'sweetalert';
import {
    formatError,
    login,
    // runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password,first_name ,last_name, history) {
    return (dispatch) => {
        signUp( email,password,first_name,last_name)
        .then((response) => {
            // saveTokenInLocalStorage(response.data);
            // runLogoutTimer(
            //     dispatch,
            //     response.data.expiresIn * 1000,
            //     history,
            // );
            // dispatch(confirmedSignupAction(response.data));
            swal("Success", `${response?.data?.message}`, "success");
            history.push('/login');
        })
        .catch((error) => {
            swal("Opps", `${error?.response?.data?.message}`, "error");

            // const errorMessage = formatError(error.response.data.errors);
            dispatch(signupFailedAction(error?.response?.data?.errors));
        });
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, history) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                console.log(response,'redux')
                if(response.data.status === true ){
                    saveTokenInLocalStorage(response.data.token);
                    // runLogoutTimer(
                    //     dispatch,
                    //     response.data.expiresIn * 1000,
                    //     history,
                    // );
                    dispatch(loginConfirmedAction(response.data));
                    history.push('/dashboard');      
                }
                else{
                const errorMessage = formatError(response.data.message);
                dispatch(loginFailedAction(errorMessage));

                }
                         
            })
            .catch((error) => {
                swal("Opps", `${error?.response?.data?.message}`, "error");
        
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    console.log(message,"action");
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
