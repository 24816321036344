import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(email, password,first_name,last_name) {
    // axios call
    const postData = {
        email,
        password,
        first_name,
        last_name,
        returnSecureToken: true,
    };
    return axios.post(
        `https://recruitment.dev-nuh.xyz/api/staff/register`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://recruitment.dev-nuh.xyz/api/staff/login`,
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse) {
        case 'EMAIL_EXISTS':
            // return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'Email Not Found':
            // return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'Invalid Credential':
            // return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
            case 'Please Active Your Account':
                // return 'Invalid Password';
                swal("Oops", "Please Active Your Account", "error");
                break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    console.log(tokenDetails);
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    // const expireDate = new Date(tokenDetails.expireDate);
    // const todaysDate = new Date();

    // if (todaysDate > expireDate) {
    //     dispatch(logout(history));
    //     return;
    // }
    dispatch(loginConfirmedAction(tokenDetails));

    // const timer = expireDate.getTime() - todaysDate.getTime();
    // runLogoutTimer(dispatch, timer, history);
}
