import React, { Suspense, lazy, useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import Setting from "./layouts/Setting";
//
import { ThemeContext } from "../context/ThemeContext";

import LoadingScreen from "./components/common/LoadingScreen";
/// Dashboard
const Home = lazy(() => import("./pages/Home.js"));
const HomeCountFilter = lazy(() => import("./pages/HomeCountFilter"));

// candidates
const CandidateList = lazy(() => import("./pages/Candidate/List"));
const AddCandidate = lazy(() => import("./pages/Candidate/Add"));
const UpdateCandidate = lazy(() => import("./pages/Candidate/Update"));
const CandidateDetail = lazy(() => import("./pages/Candidate/Detail"));

// final interview liset
const interviewList = lazy(() => import("./pages/finalinterview/List"));
// Manager
const ManagerList = lazy(() => import("./pages/Manager/Manager"));
// EMail
const EmailSetting = lazy(() => import("./pages/EmailSetting/Add"));
/// Pages

const Error404 = lazy(() => import("./pages/Error404"));

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "dashboard/status", component: HomeCountFilter },

    // Candidate
    { url: "Candidate", component: CandidateList },
    { url: "Candidate/add", component: AddCandidate },
    { url: "Candidate/update/:id", component: UpdateCandidate },
    { url: "Candidate/detail/:id", component: CandidateDetail },

    // final interview
    { url: "finalinterview", component: interviewList },

    // Email
    { url: "email", component: EmailSetting },

    // Manager
    { url: "Manager", component: ManagerList },

    { url: "*", component: Error404 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Suspense fallback={<LoadingScreen />}>
              <Switch>
                {routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}
              </Switch>
            </Suspense>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
