import React from 'react'
import Loader from '../../../images/loader.gif';

const LoadingScreen = () => {
  return (
    <div class="overlay">
  <div class="preloader">
    <img src={Loader} alt="Loader" />
  </div>
</div>
  )
}

export default LoadingScreen