

export const ADD_GLOBAL_DATE_STATES = '[ADD_DATE action] confirmed ADD_DATES';
export const RESET_GLOBAL_DATE_STATES = '[RESET_DATE action] confirmed RESET_DATES';



export function AddDatesAction(data) {
    return {
        type: ADD_GLOBAL_DATE_STATES,
        payload: data,
    };
}

export function ResetDatesAction(data) {
    return {
        type: RESET_GLOBAL_DATE_STATES,
    };
}