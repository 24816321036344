import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  loadingToggleAction,
  signupAction,
} from "../../store/actions/AuthActions";
import LoadingScreen from "../components/common/LoadingScreen";
// image
//import logo from "../../images/logo-full.png";

function Register(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "", firstname: "", lastname: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [firstname, SetFirstname] = useState("");
  const [lastname, SetLastname] = useState("");
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    if (firstname === "") {
      errorObj.firstname = "firstname is Required";
      error = true;
    }
    if (lastname === "") {
      errorObj.lastname = "lastname is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    setLoader(true);

    dispatch(loadingToggleAction(true));
    dispatch(signupAction(email, password, firstname, lastname, props.history));
    setLoader(false);
  }
  console.log(props);
  return (
    <>
      {loader ? (
        <LoadingScreen />
      ) : (
        <div className="authincation h-100 p-meddle">
          <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-md-6">
                <div className="authincation-content">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form">
                        <div className="text-center mb-3">
                          {/* <Link to="/login">
							<img src={logo} alt="" />
						</Link> */}
                        </div>
                        <h4 className="text-center mb-4 ">
                          Sign up your account
                        </h4>
                        {props.errorMessage && (
                          <div className="alert alert-danger" role="alert">
                            {[props.errorMessage].map((object) =>
                              Object.values(object).map((val) => <p>{val}</p>)
                            )}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="">{props.successMessage}</div>
                        )}
                        <form onSubmit={onSignUp}>
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>first name</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="first name"
                              value={firstname}
                              onChange={(e) => SetFirstname(e.target.value)}
                            />
                          </div>
                          {errors.firstname && (
                            <p className="text-danger">{errors.firstname}</p>
                          )}
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>last name</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="last name"
                              value={lastname}
                              onChange={(e) => SetLastname(e.target.value)}
                            />
                          </div>
                          {errors.lastname && (
                            <p className="text-danger">{errors.lastname}</p>
                          )}

                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="form-control"
                              placeholder="hello@example.com"
                            />
                          </div>
                          {errors.email && (
                            <p className="text-danger">{errors.email}</p>
                          )}
                          <div className="form-group mb-3">
                            <label className="mb-1 ">
                              <strong>Password</strong>
                            </label>
                            <input
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control"
                              defaultValue="Password"
                            />
                          </div>
                          {errors.password && (
                            <p className="text-danger">{errors.password}</p>
                          )}
                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Sign me up
                            </button>
                          </div>
                        </form>
                        <div className="new-account mt-3">
                          <p className="">
                            Already have an account?{" "}
                            <Link className="text-primary" to="/login">
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
