/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

/// Image
// import profile from "../../../images/profile/pic1.jpg";
// import plus from "../../../images/plus.png";

class MM extends Component {
	  componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	  }

	  componentWillUnmount() {
	  }

	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		);
	}
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	// sidebar icon Heart blast
	// var handleheartBlast = document.querySelector('.heart');
  //       function heartBlast() {
  //           return handleheartBlast.classList.toggle("heart-blast");
  //       }
  //       handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
  const scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  const fullPath = window.location.pathname.split("/");
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  const deshBoard = [
      "dashboard",
      "dashboard-dark",
      "guest-list",
      "guest-detail",
      "concierge",
      "room-list",
      "reviews",
      "task",
    ];
 

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll mt-5">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="" to="/dashboard" >
              <i className="flaticon-025-dashboard" />
              <span className="nav-text">Dashboard</span>
            </Link>
  
          </li>
          <li className={`${fullPath.includes("candidate") ? "mm-active" : ""}`}>
            <Link className="" to="/candidate" >
              <i className="fa fa-users" />
              <span className="nav-text">Candidate</span>
            </Link>
          </li>
          <li className={`${fullPath.includes("finalinterview") ? "mm-active" : ""}`}>
            <Link className="" to="/finalinterview" >
              <i className="flaticon-050-info" />
              <span className="nav-text">final interview list</span>
            </Link>
            
          </li>
          {/* <li className={`${fullPath.includes("Manager") ? "mm-active" : ""}`}>
            <Link className="" to="/Manager" >
              <i className="flaticon-050-info" />
              <span className="nav-text">Manager</span>
              </Link>
              </li> */}
          <li className={`${fullPath.includes("status") ? "mm-active" : ""}`}>
            <Link className="" to="/dashboard/status/?type=hires" >
              <i className="fa fa-plus" />
              <span className="nav-text">Hires  list</span>
            </Link>
            
          </li>
        
        </MM>
		
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
