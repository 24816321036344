import { lazy, Suspense, useEffect } from "react";

/// Components
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Index from "./jsx";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./vendor/datatables/css/dataTables.min.css";
import 'bootstrap/dist/css/bootstrap.css';
import "./css/style.css";
import "react-dropzone-uploader/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Registration from "./jsx/pages/Registration";
const Login = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
    })
);
// const Register = import("./jsx/pages/Registration");


function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch, props.history);
  }, [dispatch, props.history]);

  const routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Registration} />

    </Switch>
  );
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1" />
                <div className="sk-child sk-bounce2" />
                <div className="sk-child sk-bounce3" />
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  }
  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1" />
              <div className="sk-child sk-bounce2" />
              <div className="sk-child sk-bounce3" />
            </div>
          </div>
        }
      >
        {routes}
      </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
});

export default withRouter(connect(mapStateToProps)(App));
